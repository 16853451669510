import React from "react";
import { ThemeProvider } from "react-jss";
import chroma from "chroma-js";
import { Helmet } from "react-helmet";

import { DEFAULT_BRAND_COLOR, ROOT_EL, ENVIRONMENT } from "localConstants";
import pkg from "../package.json";
import AppContainer from "./components/AppContainer";

const App = () => {
  // Will need to change contrast ratio to 4.5 if we need to
  // meet WCAG AA for small text or introduce a "high contrast mode"
  const contrastRatio = 3;
  const makeColorAccessible = (color) => {
    let colorContrast = chroma.contrast(color, "white");
    let newColor = color;
    while (colorContrast < contrastRatio) {
      newColor = chroma(color).darken(0.05);
      colorContrast = chroma.contrast(color, "white");
    }
    return newColor;
  };
  //
  // Theme Colors
  //

  // Primary
  const primaryColor = chroma(
    ROOT_EL.getAttribute("data-brand-color") || chroma(DEFAULT_BRAND_COLOR)
  );
  const hasGoodContrast =
    chroma.contrast(primaryColor, "white") > contrastRatio;
  const useWhiteOrBlack = hasGoodContrast ? "white" : "black";
  const canHoverDarker = chroma.deltaE(primaryColor, "black") > 40;
  const primaryHover = canHoverDarker
    ? primaryColor.darken(0.25)
    : primaryColor.brighten(1);

  // Primary Accessible
  const primaryA11y = hasGoodContrast
    ? primaryColor
    : makeColorAccessible(primaryColor);
  const hasGoodContrastA11y =
    chroma.contrast(primaryA11y, "white") > contrastRatio;
  const useWhiteOrBlackA11y = hasGoodContrastA11y ? "white" : "black";
  const canHoverDarkerA11y = chroma.deltaE(primaryA11y, "black") > 40;
  const primaryA11yHover = canHoverDarkerA11y
    ? primaryA11y.darken(0.25)
    : primaryA11y.brighten(1);

  return (
    <ThemeProvider
      theme={{
        primary: {
          color: primaryColor,
          hoverColor: primaryHover,
          textColor: useWhiteOrBlack,
          hasGoodContrast,
        },
        accessible: {
          primary: {
            color: primaryA11y,
            hoverColor: primaryA11yHover,
            textColor: useWhiteOrBlackA11y,
            hasGoodContrast: hasGoodContrastA11y,
          },
        },
      }}
    >
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="version" content={pkg.version} />
        </Helmet>
        <AppContainer />
      </>
    </ThemeProvider>
  );
};

export default App;
