import React, { useState, useEffect, useContext } from "react";
import GoogleMapReact from "google-map-react";
import { fitBounds } from "google-map-react/utils";
import { useTheme } from "react-jss";
import SuperCluster from "supercluster";
import { CSSTransition } from "react-transition-group";

import { LocationsContext } from "context/Locations";

import { ROOT_EL, DEFAULT_SEARCH_RADIUS } from "localConstants";
import MarkerCluster from "./MarkerCluster";
import Marker from "./Marker";
import CenterPin from "./CenterPin";
import Loader from "./Loader";
import isEmpty from "../../utilities/isEmpty";
import useStyles from "./styles";

const Map = ({ children, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const {
    filteredLocations,
    searchRadius,
    loading,
    locations,
    visibleLocations,
    locationDetail,
    isMapLoaded,
    setIsMapLoaded,
    bounds,
    setBounds,
    searchCenter,
    setSearchCenter,
    hasValidSearch,
    noResults,
  } = useContext(LocationsContext);
  const defaultProps = {
    clusterRadius: 60,
    center: {
      lat: 39.5,
      lng: -98.35,
    },
    mapZoom: 4,
  };

  const [mapsObj, setMapsObj] = useState({});
  const [size, setSize] = useState({});
  const [mapCenter, setMapCenter] = useState(defaultProps.center);
  const [mapZoom, setMapZoom] = useState(defaultProps.mapZoom);
  const [clusters, setClusters] = useState();
  const [superClusters, setSuperClusters] = useState();
  const [showNewSearchBtn, setShowNewSearchBtn] = useState();
  const { googleApiKey } = ROOT_EL.dataset;

  // Recreate bounds format used by google-map-react
  const createBounds = (boundsObj) => {
    return {
      ne: {
        lat: boundsObj.getNorthEast().lat(),
        lng: boundsObj.getNorthEast().lng(),
      },
      sw: {
        lat: boundsObj.getSouthWest().lat(),
        lng: boundsObj.getSouthWest().lng(),
      },
      // FIXME: This is kinda hacky. Need this to make available
      // to Provider's setVisibleLocations.
      methods: boundsObj,
    };
  };

  useEffect(() => {
    if (isMapLoaded && !loading && hasValidSearch) {
      const viewableLocations =
        (filteredLocations && filteredLocations) || locations;
      const isNotLocationDetail = isEmpty(locationDetail);
      const currentBounds = new mapsObj.maps.LatLngBounds();
      const isMapBoundsLoaded = !isEmpty(mapsObj.maps);

      if (isMapBoundsLoaded) {
        // Single Result / Location Detail
        if (viewableLocations.length === 1 || !isNotLocationDetail) {
          const singleLocation = isNotLocationDetail
            ? viewableLocations[0].location
            : locationDetail;
          // Set center & zoom when a specific location is selected
          // OR if there's only 1 search result as we need to explicitly set the
          // zoom & center to that location or it will move map to the other side
          // of the world since fitBounds expects multiple markers
          setMapCenter({
            lat: singleLocation.latitude,
            lng: singleLocation.longitude,
          });
          setMapZoom(isNotLocationDetail ? 14 : 16);
        }

        // More than 1 result
        if (viewableLocations.length > 1 && isNotLocationDetail) {
          // Grab all locations within 5 miles
          // OR all within filtered distance radius
          // OR the closest 10 locations
          // ELSE all within default search radius
          let initiallyVisibleLocations = [];
          for (
            let searchDistance =
              searchRadius !== DEFAULT_SEARCH_RADIUS ? searchRadius : 5;
            initiallyVisibleLocations.length < viewableLocations.length &&
            initiallyVisibleLocations.length < 10;
            searchDistance += 1
          ) {
            initiallyVisibleLocations = viewableLocations.filter(
              (location) => Math.ceil(location.distance) < searchDistance
            );
          }

          // Extend map bounds to the closest locations
          initiallyVisibleLocations.forEach((marker) => {
            currentBounds.extend(
              new mapsObj.maps.LatLng(
                marker.location.latitude,
                marker.location.longitude
              )
            );
          });
          const newBounds = createBounds(currentBounds);
          const { center: newCenter, zoom: newZoom } = fitBounds(newBounds, {
            width: size.width,
            height: size.height,
          });
          setBounds(newBounds);
          setMapCenter(newCenter);
          setMapZoom(newZoom);
        }

        // No Results
        if (noResults) {
          setMapCenter(searchCenter);
          setMapZoom(7);
        }
      }
    }
    // FIXME: Can't add setBounds as it will trigger infinite loop. Figure out why
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    noResults,
    hasValidSearch,
    isMapLoaded,
    locations,
    filteredLocations,
    locationDetail,
    size.height,
    size.width,
    mapsObj.map,
    mapsObj.maps,
    loading,
    searchRadius,
    searchCenter,
  ]);

  // Update the clustered Locations
  useEffect(() => {
    const createClusters = () => {
      // Add GeoJSON format https://geojson.org/ for supercluster
      // **NOTE** They use the order [longitude, latitude] due to some
      // academic mathematical reasons instead of the natural order of
      // [latitude, longitude] used literally everywhere else. 🤦‍
      const convertedVisibleLocations = visibleLocations.map((location) => {
        location.location.type = "Feature";
        location.location.geometry = {
          type: "Point",
          coordinates: [
            location.location.longitude,
            location.location.latitude,
          ],
        };
        location.location.properties = {
          name: location.location.name,
        };
        return location.location;
      });

      let newClusters = [];
      const dynamicRadius = () => {
        if (mapZoom === 11) return defaultProps.clusterRadius - 5;
        if (mapZoom === 12) return defaultProps.clusterRadius - 20;
        return defaultProps.clusterRadius;
      };

      if (mapZoom < 13) {
        const superCluster = new SuperCluster({
          radius: dynamicRadius(),
        }).load(convertedVisibleLocations);
        setSuperClusters(superCluster);
        newClusters = superCluster.getClusters(
          [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat],
          mapZoom
        );
        // Add children locations to cluster so we can
        // query against them
        newClusters.forEach((cluster) => {
          if (cluster.properties.cluster) {
            cluster.children = superCluster.getChildren(cluster.id);
          }
        });
      } else {
        newClusters = convertedVisibleLocations;
      }
      setClusters(newClusters);
      return newClusters;
    };

    if (isMapLoaded && visibleLocations.length > 0) {
      createClusters();
    } else if (visibleLocations.length === 0) {
      setClusters([]);
    }
  }, [
    isMapLoaded,
    visibleLocations,
    mapZoom,
    defaultProps.clusterRadius,
    bounds,
  ]);

  const apiIsLoaded = (map, maps) => {
    setMapsObj({ map, maps });
    setIsMapLoaded(!isEmpty({ map, maps }));
    setBounds(createBounds(map.getBounds()));
  };

  const handleChange = (newMapCenter, deleteMeZoom, newBounds, newSize) => {
    if (newMapCenter) setMapCenter(newMapCenter);
    const { zoom: newZoom } = fitBounds(newBounds, size);
    if (newZoom) setMapZoom(newZoom);
    if (newBounds && !isEmpty(mapsObj)) {
      setBounds({ ...newBounds, methods: mapsObj.map.getBounds() });
    }
    if (newSize) setSize(newSize);

    // Show search this area button
    if (!isEmpty(bounds) && newBounds) {
      const latDifference = Math.abs(bounds.ne.lat - bounds.sw.lat);
      const lngDifference = Math.abs(bounds.ne.lng - bounds.sw.lng);
      const latPercentChanged = Math.abs(
        (mapCenter.lat - newMapCenter.lat) / latDifference
      );
      const lngPercentChanged = Math.abs(
        (mapCenter.lng - newMapCenter.lng) / lngDifference
      );
      setShowNewSearchBtn(
        !!(latPercentChanged > 0.25 || lngPercentChanged > 0.25)
      );
    }
  };

  const handleClusterClick = (cluster) => {
    setMapCenter({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
    setMapZoom(superClusters.getClusterExpansionZoom(cluster.id));
  };

  const handleRedoSearch = () => {
    setShowNewSearchBtn(false);
    setSearchCenter(mapCenter);
  };

  return (
    // Important! Always set the container height explicitly
    <div className={classes.map}>
      <div className={classes.redoSearchWrapper}>
        <CSSTransition
          in={showNewSearchBtn && hasValidSearch && isEmpty(locationDetail)}
          timeout={200}
          classNames={{
            appear: classes.appear,
            enter: classes.enter,
            enterDone: classes.enterDone,
            exit: classes.exit,
            exitDone: classes.exitDone,
          }}
        >
          <button
            type="button"
            onClick={handleRedoSearch}
            className={classes.redoSearch}
          >
            Search this area
          </button>
        </CSSTransition>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          libraries: "places",
          key: googleApiKey || "AIzaSyAu7yAW7FvJaAHJ1BHMeZcAuA73CVjZKFs",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.mapZoom}
        resetBoundsOnResize
        center={mapCenter}
        zoom={mapZoom}
        hoverDistance={45} // 90 / 2
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          apiIsLoaded(map, maps);
        }}
        onChange={({
          center: newMapCenter,
          zoom: newZoom,
          bounds: newBounds,
          size: newSize,
        }) => handleChange(newMapCenter, newZoom, newBounds, newSize)}
      >
        {/* Need to have separate lat/lng props for GoogleMapReact */}
        <CenterPin
          key="search-location-pin"
          lat={(searchCenter && searchCenter.lat) || defaultProps.center.lat}
          lng={(searchCenter && searchCenter.lng) || defaultProps.center.lng}
          className={classes.locationPin}
        />

        {locations.length &&
          clusters &&
          clusters.map((visibleItem) => {
            if (visibleItem.properties.cluster) {
              return (
                <MarkerCluster
                  key={`cluster-${visibleItem.id}`}
                  lat={visibleItem.geometry.coordinates[1]}
                  lng={visibleItem.geometry.coordinates[0]}
                  count={visibleItem.properties.point_count_abbreviated}
                  locations={visibleItem.children}
                  onClick={() => handleClusterClick(visibleItem)}
                />
              );
            }
            return (
              <Marker
                key={`marker-${visibleItem.id}`}
                lat={visibleItem.latitude}
                lng={visibleItem.longitude}
                name={visibleItem.name}
                location={visibleItem}
              />
            );
          })}
      </GoogleMapReact>
      {loading && <Loader />}
    </div>
  );
};

export default Map;
