import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  detailWrapper: {
    paddingBottom: 40
  },

  name: {
    fontSize: 16,
    margin: '0 0 10px',
    paddingBottom: 20,
    borderBottom: '1px solid #eee'
  },

  backLink: {
    background: 'none',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 -5px',
    paddingLeft: 5,
    color: `${theme.accessible.primary.color}!important`,
    fontSize: 12,
    fontWeight: 'bold',
    cursor: 'pointer',
    padding: '15px 0',

    '& svg': {
      transition: '0.2s cubic-bezier(0, 0.2, 0.8, 1)',
      transform: 'translateX(0)',
      display: 'inline-block'
    },

    '&:hover svg, &:focus svg, &:active svg': {
      transform: 'translateX(-5px)',
      transition: '0.2s cubic-bezier(0, 0.2, 0.8, 1)'
    }
  },

  backLinkIcon: {
    width: '1.25em',
    height: '1.25em',
    marginRight: '-5px'
  },

  backLinkText: {
    marginLeft: 7,
    display: 'inline-block'
  },

  iconWrapper: {
    marginRight: 5
  },
  locationDetail: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    padding: '10px 20px',
    transition: 'background-color 0.2s cubic-bezier(0, 0.2, 0.8, 1)',
    marginLeft: -20,
    width: 'calc(100% + 40px)',

    // Force wrapping
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',

    '&:hover, &:focus, &:active': {
      backgroundColor: '#ebecf0'
    }
  },
  noneText: {
    margin: 0,
    fontSize: 12,
    lineHeight: 1.2
  },
  street: {
    margin: 0,
    fontSize: 12,
    lineHeight: 1.2
  },
  cityStateZip: {
    margin: 0,
    fontSize: 12,
    lineHeight: 1.2
  },
  detailLink: {
    fontSize: 12,
    color: `${theme.accessible.primary.color.css()}!important`,
    textDecoration: 'none',
    borderBottom: 'none!important',

    '&:hover, &:focus, &:active': {
      color: 'inherit',
      textDecoration: 'underline'
    }
  },
  phone: {
    fontSize: 12
  },
  directionsLink: {
    display: 'block',
    margin: '30px 0',
    padding: '10px 15px',
    color: `${theme.accessible.primary.textColor}!important`,
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'none',
    backgroundColor: `${theme.accessible.primary.color}`,
    borderRadius: 5,
    border: '0!important',
    transition: 'background-color 0.2s cubic-bezier(0, 0.2, 0.8, 1)',

    '&:hover, &:focus, &:active': {
      color: `${theme.accessible.primary.textColor}`,
      backgroundColor: `${theme.accessible.primary.hoverColor}`,
      textDecoration: 'none'
    }
  },

  accordionHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.primary.color}`,
    paddingBottom: 10
  },

  amenitiesHeading: {
    margin: 0,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase'
  },

  accordionIcon: {
    order: 1,
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },

  accordionIconIsActive: {
    transform: 'rotate(180deg)'
  },

  legal: {
    fontSize: '12px'
  },

  amenities: {
    margin: 0,
    marginBottom: 30,
    padding: '0',
    listStyleType: 'none',
    '& li': {
      padding: '15px 0',
      fontSize: 14,
      borderBottom: '1px solid #ddd'
    }
  },
  packageDetails: {
    padding: '0',
    '& h6': {
      marginBottom: 0,
      padding: '5px 0'
    },
    '& p': {
      margin: 0,
      fontSize: 12,
      lineHeight: '20px'
    }
  },

  noTopMargin: {
    marginTop: 0
  },

  sectionHeader: {
    borderBottom: `2px solid ${theme.primary.color}`,
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: 10
  },

  // Tooltips
  // TODO: Extract this
  '@global': {
    '.tippy-tooltip': {
      backgroundColor: '#505355',
      padding: '.25rem .4375rem',
      fontSize: 14,
      fontWeight: '600',
      fontFamily: 'inherit'
    },

    '[x-placement^=top] .tippy-arrow': {
      borderTop: '8px solid #505355',
      borderRight: '8px solid transparent',
      borderLeft: '8px solid transparent'
    },

    '[x-placement^=bottom] .tippy-arrow': {
      borderBottom: '8px solid #505355',
      borderRight: '8px solid transparent',
      borderLeft: '8px solid transparent'
    },

    '[x-placement^=left] .tippy-arrow': {
      borderLeft: '8px solid #505355',
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent'
    },

    '[x-placement^=right] .tippy-arrow': {
      borderRight: '8px solid #505355',
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent'
    },

    '.tippy-backdrop': {
      backgroundColor: '#505355'
    },

    '.tippy-roundarrow': {
      fill: '#505355'
    },

    '[data-animatefill]': {
      backgroundColor: 'transparent'
    },
    '[data-size=small]': {
      fontSize: 10,
      padding: '.1875rem .375rem'
    },
    '[data-size=large]': {
      fontSize: 18,
      padding: '.375rem .75rem'
    }
  }
}))

export default useStyles
