import React, { useContext } from 'react'
import { useTheme } from 'react-jss'
import { LocationsContext } from 'context/Locations'

import useStyles from './styles'

const Item = ({ location, style, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() })
  const loc = location.location
  const trimmedDistance = location.distance.toFixed(1)
  const { setLocationDetail, setLocationHover } = useContext(LocationsContext)

  const handleClick = () => {
    setLocationDetail(loc)
  }

  const handleMouseEnter = () => {
    setLocationHover(loc)
  }

  const handleMouseLeave = () => {
    setLocationHover()
  }

  // Width style hack to show hover border with react-virtualized
  return (
    <div
      style={{ ...style, width: 'calc(100% - 6px)' }}
      className={classes.itemWrapper}
    >
      <button
        type="button"
        className={classes.item}
        onClick={e => handleClick(e)}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <h4 className={classes.itemName}>{loc.name}</h4>
        <div className={classes.itemAddress}>
          <p>{loc.street}</p>
          <span>
            {' '}
            {trimmedDistance} mile{trimmedDistance === 1 ? '' : 's'}
          </span>
        </div>
      </button>
    </div>
  )
}

export default Item
