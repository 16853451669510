import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from 'react-jss'

import { LocationsContext } from 'context/Locations'
import useStyles from './styles'

const Marker = ({ location, name, $hover, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() })

  const { locationDetail, setLocationDetail, locationHover } = useContext(
    LocationsContext
  )
  const [isHovering, setIsHovering] = useState(false)

  let active = false

  // Apply active state if location is selected
  if (Object.keys(locationDetail).length) {
    if (locationDetail.id === location.id) {
      active = true
    }
  }

  useEffect(() => {
    setIsHovering(false)

    // Apply hover state if user hovers over corresponding card
    if (locationHover) {
      if (locationHover.id === location.id) {
        setIsHovering(true)
      }
    }
  }, [locationHover, location.id])

  return (
    <button
      type="button"
      className={`${classes.marker} ${active &&
        classes.markerActive} ${(isHovering || $hover) && classes.markerHover}`}
      onClick={e => setLocationDetail(location)}
    >
      <div
        className={`${classes.markerName} ${active &&
          classes.markerNameActive}`}
      >
        {name}
      </div>
      <svg viewBox="0 0 16 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <g
            className={
              !active ? classes.markerGroup : classes.markerGroupActive
            }
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
              fill="currentColor"
            />
            <circle fill="#FFF" cx="8" cy="7.5" r="3" />
          </g>
        </g>
      </svg>
    </button>
  )
}
export default Marker
