import React from 'react'
import { isIE, osVersion, browserVersion } from 'react-device-detect'

export default error => {
  let message = ''

  switch (error.code) {
    case error.PERMISSION_DENIED:
      if (isIE && browserVersion === '11.0' && osVersion.startsWith('10')) {
        message = (
          <div>
            <p>We don't have your permission to use your location.</p>
            <p>
              <a
                href="https://support.microsoft.com/en-us/help/4468240/windows-10-location-service-and-privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ensure that you've turned on Windows privacy location sharing.
              </a>
            </p>
          </div>
        )
      } else if (error.message.startsWith('Only secure origins are allowed')) {
        message = (
          <div>
            <p>We can only retrieve your location on a secure website.</p>
            <p>Please type in your location instead.</p>
          </div>
        )
      } else {
        message = (
          <div>
            <p>Please turn on location sharing to use your current location.</p>
            <p>Or you can just type in your location.</p>
          </div>
        )
      }

      break
    case error.POSITION_UNAVAILABLE:
      message = (
        <div>
          <p>Your current position could not be determined.</p>
          <p>Please type in your location instead.</p>
        </div>
      )
      break
    case error.PERMISSION_DENIED_TIMEOUT:
      message = (
        <div>
          <p>It took too long to get your current location.</p>
          <p>Please type in your location instead.</p>
        </div>
      )
      break
    case error.UNKNOWN_ERROR:
    default:
      message = (
        <div>
          <p>
            Your current position could not be determined for an unknown reason.
          </p>
          <p>Please type in your location instead.</p>
        </div>
      )
  }

  // If it's an unknown error, build a message that includes
  // information that helps identify the situation, so that
  // the error handler can be updated.
  if (message === '') {
    const strErrorCode = error.code.toString()
    message = (
      <div>
        <p>
          Your position could not be determined due to an unknown error (Code:{' '}
          {strErrorCode}")
        </p>
      </div>
    )
  }

  return message
}
