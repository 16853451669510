import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFemale } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "react-jss";

import { DEFAULT_SEARCH_RADIUS } from "localConstants";
import { LocationsContext } from "context/Locations";
import { useStyles, selectStyles } from "./styles";
import { amenitiesOptions, distanceOptions, amenitiesMap } from "./data";

const Filters = (props) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  const locationsContext = useContext(LocationsContext);
  const {
    locations,
    setFilteredLocations,
    setSearchRadius,
    isMapLoaded,
    activeTier,
    locationDetail,
    programCode,
    hiddenClasses,
    setHiddenClasses,
  } = locationsContext;
  const [availableAmenityOptions, setAvailableAmenityOptions] = useState(
    amenitiesOptions
  );

  //
  // Distance Filter
  //
  const [distanceFilter, setDistanceFilter] = useState();

  const handleDistanceChange = (selectedDistance) => {
    if (selectedDistance) {
      setDistanceFilter(selectedDistance);
      setSearchRadius(selectedDistance.value);
    } else {
      // Reset to default
      setDistanceFilter(null);
      setSearchRadius(DEFAULT_SEARCH_RADIUS);
    }
  };

  //
  // Women Only Filter
  //
  const [womenOnlyFilter, setWomenOnlyFilter] = useState(false);
  const handleWomenFilter = () => {
    setWomenOnlyFilter(!womenOnlyFilter);
  };

  //
  // Amenities Filter
  //
  const [amenitiesFilter, setAmenitiesFilter] = useState([]);
  const handleAmenityChange = (selectedAmenities) => {
    setAmenitiesFilter(selectedAmenities);
  };

  //
  // Update Locations on Amenity Filter Changes
  // ------------------------------------------------------
  // Convert Selected option values to IDs (if applicable) and
  // return objects with arrays for string values and IDs
  const getSelectedAmenities = (selectedOptions) => {
    const selectedAmenities = { values: [], ids: [] };

    // Amenities Filter
    selectedOptions.forEach((option) => {
      if (Object.prototype.hasOwnProperty.call(amenitiesMap, option.value)) {
        if (typeof amenitiesMap[option.value].ids !== "undefined") {
          if (amenitiesMap[option.value].ids.length > 1) {
            selectedAmenities.ids.push(amenitiesMap[option.value].ids);
          } else {
            selectedAmenities.ids.push(...amenitiesMap[option.value].ids);
          }
        } else {
          selectedAmenities.values.push(option.value);
        }
      }
    });

    // Women Only Toggle
    if (womenOnlyFilter) {
      selectedAmenities.values.push(amenitiesMap.womenOnly.convertedValue);
    }
    return selectedAmenities;
  };

  // Hide Classes
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    switch (programCode) {
      case "prime_lte":
      case "prime_std":
      case "prime_lgcy":
        return setHiddenClasses("all");
      case "flip50":
        return setHiddenClasses({
          ids: [
            ...amenitiesMap.hasSilverSneakersClass.ids,
            ...amenitiesMap.hasBoomClass.ids,
          ],
          labels: ["flex", "boom"],
        });
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programCode]);

  // Run both filters
  useEffect(() => {
    // Filter by selected amenities or return true if none are selected
    // eslint-disable-next-line consistent-return
    const hasSelectedAmenities = (selectedOptions, { location }) => {
      // Convert list of numbers to array of numbers
      const amenityIDs =
        location.amenities &&
        location.amenities.map((amenity) => {
          return amenity.id;
        });

      if (amenityIDs) {
        const selectedAmenities = getSelectedAmenities(selectedOptions);
        const selectedIDs = selectedAmenities.ids;
        const selectedValues = selectedAmenities.values;

        const hasSelectedIDs = () => {
          // Always return true if no filters are selected
          if (selectedIDs.length === 0) return true;

          return selectedIDs.every((selectedID) => {
            let hasAmenity = false;

            selectedID.length > 1
              ? // Match amenities with array of IDs
                (hasAmenity = selectedID.some((singleID) =>
                  amenityIDs.includes(singleID)
                ))
              : // Match amenity with only one ID
                (hasAmenity = amenityIDs.includes(selectedID));

            return hasAmenity;
          });
        };
        const hasSelectedValues =
          selectedValues.length === 0 ||
          selectedValues.every(
            // Do a boolean check
            (amenity) => {
              return (
                location[amenity] ||
                // Otherwise, check to see if object passed in matches the location's value
                (location[Object.keys(amenity)[0]] &&
                  location[Object.keys(amenity)[0]] ===
                    amenity[Object.keys(amenity)[0]])
              );
            }
          );

        // Coerce to boolean & return
        return !!(hasSelectedIDs() && hasSelectedValues);
      }
    };

    // Distance Filter
    const isWithinRange = (location) => {
      return !distanceFilter
        ? true // Always true if filter isn't applied
        : location.distance < distanceFilter.value;
    };

    // Tiers Filter
    const isWithinTier = (location) => {
      return !activeTier
        ? true // Always true if no tiers exist
        : location.location.tierNumber <= activeTier;
    };

    // If we get new locations, re-check if they have selected amenities
    if (
      !amenitiesFilter &&
      !womenOnlyFilter &&
      !distanceFilter &&
      !hiddenClasses
    ) {
      setFilteredLocations(null);
    } else {
      // Filter Locations amenities against selected amenities
      const newFilteredLocations = locations.filter(
        (location) =>
          hasSelectedAmenities(amenitiesFilter, location) &&
          isWithinRange(location) &&
          isWithinTier(location)
      );
      // Update filterLocations context with new locations & recalculate the visible locations
      setFilteredLocations(
        newFilteredLocations.length > 0 ? newFilteredLocations : []
      );
    }

    // Hide classes based on what program is selected
    if (isMapLoaded) {
      const hideAllClasses = () => {
        setAvailableAmenityOptions(
          amenitiesOptions.filter((option) => option.label !== "Classes")
        );
      };
      const hideSpecificClasses = () => {
        const newAmenities = amenitiesOptions.map((amenityGroup) => {
          if (amenityGroup.label === "Classes") {
            // Loop through classes amenity filter and
            // remove any that matches hidden classes
            const filteredClasses = amenityGroup.options.filter(
              (workoutClass) =>
                !hiddenClasses.labels.some(
                  (hiddenClass) =>
                    workoutClass.label.toLowerCase() === hiddenClass
                )
            );
            amenityGroup.options = filteredClasses;
          }
          return amenityGroup;
        });
        setAvailableAmenityOptions(newAmenities);
      };
      if (hiddenClasses === "all") {
        hideAllClasses();
      } else if (hiddenClasses) {
        hideSpecificClasses();
      }
    }

    // TODO: Figure out why setX functions are returning new instances if we include
    // them in the dependency array (this isn't the only instance where it's an issue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amenitiesFilter,
    locations,
    womenOnlyFilter,
    isMapLoaded,
    distanceFilter,
    activeTier,
    hiddenClasses,
    programCode,
  ]);

  // Amenities Group Label
  const amenitiesGroupLabel = (data) => (
    <div className={classes.groupStyles}>
      <span>{data.label}</span>
      <span className={classes.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  return (
    <div className={classes.filtersContainer}>
      <span className={classes.label}>Filter By</span>
      <Select
        className={classes.reactSelectContainer}
        classNamePrefix="react-select"
        isClearable
        placeholder="Distance"
        value={distanceFilter}
        onChange={handleDistanceChange}
        options={distanceOptions}
        styles={selectStyles(theme)}
        isDisabled={Object.keys(locationDetail).length !== 0}
        aria-label="Distance Filter"
      />
      <Select
        className={classes.reactSelectContainer}
        classNamePrefix="react-select"
        placeholder="Amenities"
        isMulti
        isSearchable
        formatGroupLabel={amenitiesGroupLabel}
        value={amenitiesFilter}
        onChange={handleAmenityChange}
        options={availableAmenityOptions}
        styles={selectStyles(theme)}
        isDisabled={Object.keys(locationDetail).length !== 0}
        aria-label="Amenities Filter"
      />
      <label
        className={`
          ${classes.womenOnlyFilter}
          ${womenOnlyFilter ? classes.womenFilterIsActive : ""}
          ${
            Object.keys(locationDetail).length !== 0
              ? classes.womenFilterIsDisabled
              : ""
          }
        `}
        htmlFor="womensFilter"
      >
        <input
          className={classes.womenFilterInput}
          onChange={handleWomenFilter}
          id="womensFilter"
          type="checkbox"
          disabled={Object.keys(locationDetail).length !== 0}
          checked={womenOnlyFilter}
        />
        <svg
          className={classes.womenCheckIcon}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 10 50 L 40 86 L 90 10"
            strokeDasharray="140"
            strokeDashoffset="140"
          ></path>
        </svg>
        <div className={classes.womenFilterTextWrapper}>
          <FontAwesomeIcon icon={faFemale} />
          <span className={classes.womenFilterText}>Women Only</span>
        </div>
      </label>
    </div>
  );
};

export default Filters;
