import React from 'react'
import { useTheme } from 'react-jss'

import useStyles from './styles'

const Loader = ({ children, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() })
  return (
    <div className={classes.loader}>
      <div className={classes.loaderDotOne} />
      <div className={classes.loaderDotTwo} />
    </div>
  )
}

export default Loader
