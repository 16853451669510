import React from 'react'
import LocationsProvider from 'context/Locations/Provider'
import { useTheme } from 'react-jss'
import { ToastContainer, toast } from 'react-toastify'

import Map from '../Map'
import SidePane from '../SidePane'
import Filters from '../Filters'
import useStyles from './styles'

const AppContainer = props => {
  const classes = useStyles({ ...props, theme: useTheme() })

  return (
    <LocationsProvider>
      <div className={classes.locationFinder}>
        <SidePane />
        <div className={classes.locationFinderMapSearch}>
          <Filters />
          <Map />
        </div>
      </div>

      <div className={classes.toastWrapper}>
        <ToastContainer
          position={toast.POSITION.BOTTOM_LEFT}
          toastClassName={classes.toast}
          bodyClassName={classes.toastBody}
          progressClassName={classes.toastProgress}
        />
      </div>
    </LocationsProvider>
  )
}

export default AppContainer
