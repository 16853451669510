import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  itemWrapper: {
    // hack to allow hover state in react-virtualized
    width: 'calc(100% - 6px)',
    marginLeft: '3px',
  },

  item: {
    width: '100%',
    margin: '5px 0',
    padding: 10,
    textAlign: 'left',
    boxShadow: '0 2px 4px rgba(221, 221, 221, 0.7)',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: 5,
    cursor: 'pointer',
    transition: 'box-shadow 0.15s ease-in-out',
    '&:hover, &:focus, &:active': {
      boxShadow: `0 0 0 3px ${theme.primary.color}`,
      outline: 'none',
    },
  },
  itemName: {
    fontSize: 16,
    lineHeight: '1.2',
    fontWeight: 'bold',
    margin: 0,
    marginBottom: 8,
  },
  itemAddress: {
    display: 'flex',
    fontSize: 14,
    lineHeight: '16px',
    margin: 0,
    '& p': {
      flex: '1 1',
      margin: 0,
    },
    '& span': {
      flexBasis: 100,
      margin: 0,
      textAlign: 'right',
    },
  },
}))

export default useStyles
