import { useEffect, useState } from 'react'
import { useAxios } from './useAxios'

//
//
// IMPORTANT: Brave & other secure browsers/extensions will block this request
//
//

export default function useGetGeoFromIpAddress(isReady) {
  const getGeoFromIpAddress = useAxios('https://get.geojs.io/v1/ip/geo.json', {
    defer: true,
    json: true
  })

  const [isIpAddressTimedOut, setIsIpAddressTimedOut] = useState()
  const [geo, setGeo] = useState()

  // Set a timeout error after 30 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsIpAddressTimedOut(true)
    }, 30000)
    if (getGeoFromIpAddress.isFulfilled) clearTimeout(timeout)

    // Cleanup
    return () => clearTimeout(timeout)
  }, [getGeoFromIpAddress])

  useEffect(() => {
    if (
      isReady &&
      !getGeoFromIpAddress.error &&
      !getGeoFromIpAddress.isFulfilled
    ) {
      getGeoFromIpAddress.run()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  useEffect(() => {
    if (getGeoFromIpAddress.isFulfilled && getGeoFromIpAddress.data && !geo) {
      const lat = parseFloat(getGeoFromIpAddress.data.data.latitude)
      const lng = parseFloat(getGeoFromIpAddress.data.data.longitude)
      setGeo({ lat, lng })
    }
    // Try running call multiple times for flakey APIs
    if (getGeoFromIpAddress.error && getGeoFromIpAddress.counter < 3) {
      getGeoFromIpAddress.reload()
    }
  }, [geo, getGeoFromIpAddress])

  return { geo, getGeoFromIpAddress, isIpAddressTimedOut }
}
