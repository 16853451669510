const distanceOptions = [
  {
    label: '< 1 mile',
    value: 1,
  },
  {
    label: '< 3 mile',
    value: 3,
  },

  {
    label: '< 5 mile',
    value: 5,
  },
  {
    label: '< 10 mile',
    value: 10,
  },
  {
    label: '< 20 mile',
    value: 20,
  },
]

const amenitiesOptions = [
  {
    label: 'Classes',
    options: [
      {
        label: 'FLEX',
        value: 'hasFlex',
      },
      {
        label: 'Silver Sneakers',
        value: 'hasSilverSneakersClass',
      },
      {
        label: 'Boom',
        value: 'hasBoomClass',
      },
    ],
  },
  {
    label: 'Amenities',
    options: [
      {
        label: 'Cardiovascular Equipment',
        value: 'cardio',
      },
      {
        label: 'Resistance Equipment',
        value: 'resistance',
      },
      {
        label: 'Pool',
        value: 'pool',
      },
      {
        label: 'Hot Tub/Whirlpool',
        value: 'tub',
      },
      {
        label: 'Group Exercise',
        value: 'group',
      },
    ],
  },
]

const amenitiesMap = {
  cardio: { required: false, ids: [10] },
  resistance: { required: false, ids: [35, 120] },
  pool: { required: false, ids: [150, 160, 170] },
  tub: { required: false, ids: [40] },
  group: {
    required: false,
    ids: [420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530],
  },
  hasSilverSneakersClass: {
    required: false,
    ids: [210, 220, 230, 240, 245, 250],
  },
  hasBoomClass: { required: false, ids: [660, 670, 680] },
  hasFlex: { required: false },
  womenOnly: { required: false, convertedValue: { genderSpecific: "Women's" } },
}

export { distanceOptions, amenitiesOptions, amenitiesMap }
