import { DESKTOP_BREAKPOINT } from 'localConstants'

import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  // 1. Magic number to account for top filter bar
  map: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',

    [DESKTOP_BREAKPOINT]: {
      height: 'calc(100% - 58px)', // 1
    },

    '& > div:last-child': {
      minHeight: 250,
    },
  },

  redoSearchWrapper: {
    position: 'absolute',
    top: 10,
    left: 0,
    width: '100%',
    zIndex: 2,
    textAlign: 'center',
  },

  redoSearch: {
    backgroundColor: 'white',
    color: theme.accessible.primary.color.css(),
    padding: '10px 15px',
    borderRadius: 5,
    fontWeight: 500,
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    opacity: 0,
    boxShadow: ' 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',

    '&:hover, &:focus, &:active': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
      border: `solid 1px ${theme.primary.color.css()}`,
    },
  },

  appear: {
    opacity: 0,
  },

  enter: {
    opacity: 0,
    transform: 'translate(0, 10px)',
  },

  enterDone: {
    opacity: 1,
    transform: 'translate(0, 0)',
  },

  exit: {
    opacity: 1,
    transform: 'translate(0, 0)',
  },

  exitDone: {
    opacity: 0,
    transform: 'translate(0, 10px)',
  },
}))

export default useStyles
