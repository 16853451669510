import chroma from 'chroma-js'
import { DESKTOP_BREAKPOINT } from 'localConstants'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  searchWrapper: {
    backgroundColor: 'white',
    boxShadow: '0 2px 4px #dddddd',
    padding: '20px',
    zIndex: 1,
    position: 'sticky',
    top: 0,

    [DESKTOP_BREAKPOINT]: {
      padding: '5px 20px 13px'
    }
  },

  programTabs: {
    display: 'flex',
    marginBottom: '-20px', // remove wrapper padding
    [DESKTOP_BREAKPOINT]: {
      marginBottom: '-13px' // remove wrapper padding
    },
    marginTop: 3
  },

  programTab: {
    color: '#808080',
    padding: '1em',
    borderBottom: 'solid 4px transparent',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontWeight: '500',
    transition: 'all 0.2s ease-in-out',

    '&:hover, &:active': {
      borderBottomColor: '#808080'
    }
  },

  programTabSpacer: {
    maxWidth: '1em',
    minWidth: '3px',
    width: '100%'
  },

  programTabActive: {
    '&, &:hover, &:active': {
      color: theme.accessible.primary.color.css(),
      borderBottomColor: theme.primary.color.css()
    }
  },

  title: {
    fontSize: 21,
    marginTop: '1rem',
    marginBottom: '1rem'
  },

  searchResults: {
    flex: 'none',
    width: '100%',
    minHeight: 'calc(100% + 20px)',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    backgroundColor: 'white',
    boxShadow: '0 -2px 3px rgba(0,0,0,0.15)',
    zIndex: 1,

    [DESKTOP_BREAKPOINT]: {
      display: 'flex',
      flexBasis: 280,
      flexGrow: 1,
      flexShrink: 1,
      minWidth: '30%',
      maxWidth: 440,
      minHeight: 'auto',
      boxShadow: 'none',
      flexDirection: 'column',
      borderRight: `1px solid ${theme.primary.color}`
    }
  },
  searchResultsHeader: {
    padding: '0 20px'
  },

  loadingWrapper: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },

  loadingSpinner: {
    marginBottom: '1rem',
    fontSize: '3.5rem'
  },

  resultsWrapper: {
    flexGrow: 1,
    display: 'flex',
    height: 'auto',
    overflow: 'auto'
  },

  btn: {
    display: 'inline-block',
    fontWeight: '500',
    textAlign: 'center',
    verticalAlign: 'middle',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    border: '1px solid transparent',
    padding: '.375rem .75rem',
    fontSize: 16,
    lineHeight: '1.2',
    borderRadius: '3rem',
    cursor: 'pointer',
    boxShadow: '0 0 0 transparent',
    borderLeft: `solid 1px ${chroma(theme.primary.color).brighten(0.5)}`,
    flex: 1,
    transition: 'all .2s ease-in-out',
    color: theme.accessible.primary.textColor,
    backgroundColor: theme.accessible.primary.color.css(),
    backgroundImage: `linear-gradient(to bottom, ${chroma(
      theme.accessible.primary.color
    ).brighten(0.05)}, ${theme.accessible.primary.color.css()})`,
    textShadow: '0 -1px rgba(0,0,0, 0.5)',

    '&:hover, &:focus, &:active': {
      backgroundColor: theme.accessible.primary.hoverColor.css()
    }
  },

  // 1. Needed to reduce JSS jitters
  tierButtonGroup: {
    margin: '20px 0',
    minHeight: 39 // 1
  },

  tierName: {
    display: 'block',
    textTransform: 'uppercase',
    fontWeight: '500'
  },

  missingError: {
    color: '#c61700'
  },

  missingCode: {
    display: 'block',
    color: 'black',
    background: 'lightGray',
    padding: 5,
    margin: '10px 0'
  },

  tivityPower: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 20px 3px',
    transition: 'boxShadow 0.2s ease-in-out',
    zIndex: 1
  },

  hasSearchResults: {
    boxShadow: '0 -2px 3px rgba(0,0,0,0.2)'
  },

  tivityLogo: {
    verticalAlign: 'middle',
    height: 42,
    width: 80
  },

  poweredBy: {
    fontSize: 14,
    marginTop: 4
  }
}))

export default useStyles
