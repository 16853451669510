import { createUseStyles } from 'react-jss'

/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
// Disable no shadow & nested ternary as we
// use styles everywhere here & nesting is fine for here
const useStyles = createUseStyles(theme => ({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px'
  },

  label: {
    color: '#999',
    fontSize: '14px',
    paddingRight: '10px',

    '@media only screen and (max-width: 500px)': {
      display: 'none'
    }
  },

  reactSelectContainer: {
    '& + &': {
      marginLeft: 10
    }
  },

  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  groupBadgeStyles: {
    backgroundColor: '#ebecf0',
    borderRadius: '2em',
    color: '#172b4d',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center'
  },

  womenOnlyFilter: {
    display: 'inline-block',
    fontWeight: 'normal',
    position: 'relative',
    padding: '0 0 0 30px',
    marginLeft: 10,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',

    '&:before, input[type="checkbox"]': {
      content: '""',
      width: 18,
      height: 18,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      transition: 'all 0.2s ease-in-out',

      '@media only screen and (max-width: 500px)': {
        left: 10
      }
    },

    '&:before': {
      boxShadow: 'inset 0 0 0 1px #ccc',
      borderRadius: 3,
      transition: 'box-shadow 0.075s ease-in',

      '@media only screen and (max-width: 500px)': {
        left: 10
      }
    },

    '&:hover, &:focus, &:active,': {
      '&:before': {
        boxShadow: 'inset 0 0 0 2px #ccc'
      }
    },

    '@media only screen and (max-width: 500px)': {
      border: 'solid 1px #999999',
      padding: '4px 20px 4px 30px',
      borderRadius: 20,
      minHeight: 38,
      display: 'flex',
      alignItems: 'center'
    }
  },

  womenFilterIsActive: {
    '&:before': {
      // 9*2 = width of checkbox
      // Important to override hover state
      boxShadow: `inset 0 0 0 9px ${theme.primary.color.css()}!important`
    }
  },

  womenFilterIsDisabled: {
    color: '#aaa',
    cursor: 'not-allowed',

    '&:before': {
      backgroundColor: '#ddd',
      cursor: 'not-allowed'
    },

    '&:before, input[type="checkbox"]': {
      cursor: 'not-allowed'
    },

    '&:hover:before, &:focus:before, &:active:before': {
      boxShadow: 'none'
    }
  },

  womenFilterInput: {
    display: 'inline-block',
    verticalAlign: 'middle',
    opacity: 0,
    margin: 1, // match border width

    '&:checked + svg path': {
      strokeDashoffset: 0
    },

    '&:focus + svg, &:active + svg': {
      outline: '5px auto -webkit-focus-ring-color',
      fallbacks: {
        outline: `2px solid ${theme.primary.color.css()}`
      }
    }
  },

  womenCheckIcon: {
    position: 'absolute',
    top: '50%', // vert center hack
    transform: 'translateY(-50%)', // vert center hacktm
    left: 2,
    width: 14,
    height: 14,
    pointerEvents: 'none',

    '& path': {
      stroke: theme.primary.textColor,
      strokeWidth: '15px',
      fill: 'none',
      transition: 'stroke-dashoffset 0.12s ease-in 0s',
      transitionDelay: '0.075s'
    },

    '@media only screen and (max-width: 500px)': {
      left: 12
    }
  },

  womenFilterTextWrapper: {
    '@media only screen and (max-width: 500px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: 5,
      textAlign: 'center'
    }
  },

  womenFilterText: {
    '@media only screen and (max-width: 500px)': {
      fontSize: 10
    }
  }
}))

const selectStyles = theme => {
  return {
    control: (styles, { isDisabled, isFocused, menuIsOpen }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#ddd' : 'white',
        borderRadius: '20px',
        boxShadow: menuIsOpen
          ? `0 0 0 1px ${theme.primary.hoverColor.css()}`
          : isFocused
          ? `0 0 0 1px ${theme.primary.hoverColor.css()}, 0 0 0 0.2rem ${theme.primary.color
              .alpha(0.5)
              .css()}`
          : 'none',
        borderColor: menuIsOpen ? theme.primary.hoverColor.css() : '#ccc',
        ':hover': {
          borderColor: theme.primary.hoverColor.css(),
          boxShadow: `0 0 0 1px ${theme.primary.hoverColor.css()}`
        },
        transition: 'all 0.2s ease-in-out',
        cursor: isDisabled ? 'not-allowed' : 'cursor'
      }
    },
    valueContainer: (styles, { isDisabled }) => ({
      ...styles,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      minWidth: 85,
      flexBasis: 'auto'
    }),
    clearIndicator: styles => ({ ...styles, cursor: 'pointer' }),
    dropdownIndicator: (styles, isDisabled) => ({
      ...styles,
      color: isDisabled ? '#999999' : theme.primary.color.css(),
      cursor: 'pointer',

      ':hover, :focus, :active': {
        color: theme.primary.hoverColor.css()
      }
    }),
    input: styles => ({ ...styles, cursor: 'text' }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? theme.accessible.primary.color.css()
          : isFocused
          ? theme.primary.hasGoodContrast
            ? theme.primary.color.alpha(0.25).css()
            : theme.primary.color.css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? theme.accessible.primary.textColor
          : theme.primary.color,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        transition: '0.2s ease-in-out',

        ':hover, :active, :focus': {
          backgroundColor: !isSelected && '#ebecf0'
        }
      }
    },
    container: styles => ({
      ...styles,
      color: '#4a4a4a',
      fontSize: '14px'
    }),
    menu: styles => {
      return {
        ...styles,
        zIndex: 5
      }
    },
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: theme.accessible.primary.color.css()
      }
    },
    multiValueLabel: styles => {
      return {
        ...styles,
        color: theme.accessible.primary.textColor
      }
    },
    multiValueRemove: styles => {
      return {
        ...styles,
        color: theme.primary.textColor,
        cursor: 'pointer',
        ':hover, :focus, :active': {
          backgroundColor: theme.accessible.primary.hoverColor.css(),
          color: 'white'
        }
      }
    },
    placeholder: styles => {
      return {
        ...styles,
        fontSize: '14px',
        fontWeight: 'bold'
      }
    }
  }
}

export { useStyles, selectStyles }
