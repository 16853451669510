import { IS_EVENTS } from 'localConstants'

export default (programCode, isPlural = true) => {
  if (IS_EVENTS) {
    return `Event${isPlural ? 's' : ''}`
  }

  switch (programCode) {
    case 'prime_lte':
    case 'prime_std':
    case 'prime_lgy':
    case 'flip50':
      return `Location${isPlural ? 's' : ''}`
    case 'studio':
      return `Studio${isPlural ? 's' : ''}`
    default:
      return `Location${isPlural ? 's' : ''}`
  }
}
